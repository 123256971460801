<template>
  <div class="about">
    <div class="img-block mb-14">
      <picture class="block w-full">
        <img src="/imgs/content/photos/about.jpg" alt="Holy Chic About" class="block w-full">
      </picture>
    </div>
    <section class="mb-14">
      <div class="container">
        <h2 class="text-4xl font-pt-mono mb-4">{{ $t('about') }}</h2>
        <p class="global-text-sm text-sm  mb-8 font-qanelas-light">
          {{ $t('about_par_1') }}
        </p>
        <p class="half-bordered-paragraph font-pt-mono text-2xl mb-8 leading-6">
          {{ $t('about_subtitle') }}
        </p>
        <p class="global-text-sm text-sm  font-qanelas-light">
          {{ $t('about_par_2') }}
        </p>
      </div>
    </section>
    <div class="img-block mb-14">
      <picture class="block w-full">
        <img src="/imgs/content/photos/about2.jpg" alt="Holy Chic About" class="block w-full">
      </picture>
    </div>

    <div class="container">
      <p class="global-text-sm text-sm  mb-3 font-qanelas-light">
        {{ $t('about_par_3') }}
      </p>
      <p class="global-text-sm text-sm  mb-8 font-qanelas-light">
        {{ $t('about_par_4') }}
      </p>
    </div>

    <div class="container">
      <router-link to="/rules" class="trigger-link mb-8">
          <span class="trigger-link__link-text text-red" v-html="$t('familiarize_rules_br')"></span>
          <picture class="trigger-link__arrow" >
            <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red">
              <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
              <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
              <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
            </svg>
          </picture>
      </router-link>
      <a href="javascript:void(0)" class="trigger-link justify-between mb-12" @click="$router.go(-1)" rel="nofollow">
          <span class="trigger-link__link-text order-2 grow-0 flex-grow-0" v-html="$t('go_back_br')"></span>
        <picture class="trigger-link__arrow order-1 to-back" >
          <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-black origin-center rotate-180 transform">
            <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
            <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
            <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
          </svg>
        </picture>
      </a>
    </div>

  </div>
</template>
<style lang="scss">

</style>
